<template>
    <section class="fixture-details">

        <div
            class="team-cols"
            v-if="teamData"
        >
            <div class="team">
                <h2 class="team-name">{{ teamData[0].teamName }}</h2>

                <ScoreEditor
                    @changeScore="updateScore($event, 0)"
                    :score="teamData[0].score"
                />

                <template v-if="modules.default.CustomLineups">
                    <div class="match-events">
                        <p>Match events</p>

                        <p
                            v-for="event in teamData[0].teamEvents"
                            :key="event.playerID"
                        >
                            {{ event.surname }}

                            <span
                                v-for="(item, index) in event.goals"
                                :key="`${event.playerID}-goal-${index}`"
                            >
                                <template v-if="item.type === 'Goal'">({{ item.time }}`) </template>
                            </span>

                            <span
                                v-for="(item, index) in event.bookings"
                                :key="`${event.playerID}-booking-${index}`"
                            >
                                <template v-if="item.card === 'Yellow'">(Y {{ item.time }}`) </template>
                                <template v-else-if="item.card === 'Red'">(R {{ item.time }}`) </template>
                            </span>
                        </p>
                    </div>

                    <LineupBuilder
                        :teamPlayers="teamData[0].teamPlayers"
                        @updateLineup="updateLineup($event, 0)"
                    />

                </template>

            </div>

            <div class="team">
                <h2 class="team-name">{{ teamData[1].teamName }}</h2>

                <ScoreEditor
                    @changeScore="updateScore($event, 1)"
                    :score="teamData[1].score"
                />

                <template v-if="modules.default.CustomLineups">
                    <div class="match-events">
                        <p>Match events</p>

                        <p
                            v-for="event in teamData[1].teamEvents"
                            :key="event.playerID"
                        >
                            {{ event.surname }}

                            <span
                                v-for="(item, index) in event.goals"
                                :key="`${event.playerID}-goal-${index}`"
                            >
                                <template v-if="item.type === 'Goal'">({{ item.time }}`) </template>
                            </span>

                            <span
                                v-for="(item, index) in event.bookings"
                                :key="`${event.playerID}-booking-${index}`"
                            >
                                <template v-if="item.card === 'Yellow'">(Y {{ item.time }}`) </template>
                                <template v-else-if="item.card === 'Red'">(R {{ item.time }}`) </template>
                            </span>
                        </p>
                    </div>

                    <LineupBuilder
                        :teamPlayers="teamData[1].teamPlayers"
                        @updateLineup="updateLineup($event, 1)"
                    />
                </template>

            </div>
        </div>
    </section>
</template>

<script>
import ScoreEditor from "@/components/Fixtures/ScoreEditor"
import LineupBuilder from "@/components/Fixtures/LineupBuilder"

export default {
    name: "FixtureDetails",

    components: {
        ScoreEditor,
        LineupBuilder
    },

    props: {
        friendly: {
            type: Boolean,
            default: false
        },
        seasonID: {
            type: Number
        },
        teamID: {
            type: String
        },
        matchID: {
            type: String
        },
        teamData: {
            type: Array,
            required: true
        }
    },

    data: () => ({
        modules: null
    }),

    mounted() {
        this.modules = require("../../config/features/" + process.env.VUE_APP_CLUB_ID + "/modules.js")
    },

    methods: {
        updateScore(payload, team) {
            console.log("update score");

            this.$emit("updateScore", {
                score: payload,
                team
            })
        },

        updateLineup(lineup, team) {
            console.log("update lineup", lineup);

            const matchEvents = lineup.filter(player => {
                console.log("player: ", player)

                if (player.bookings.length > 0 || player.goals.length > 0) {
                    return player
                } else {
                    return false
                }
            });

            this.$emit("updateLineup", {
                team,
                lineup,
                matchEvents,
            });
        }
    }
}
</script>

<style lang="scss" scoped>.team-cols {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.score {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.match-events {
    margin: 20px 0 0;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.team {
    width: 40%;
}

.team-name {
    margin: 0 0 10px;
}
</style>
