<template>
  <div class="single-score">
    <div class="single-score__inner">
      <button class="single-score__button" @click="scoreDown()">-</button>
      <p class="single-score__value">{{ score }}</p>
      <button class="single-score__button" @click="scoreUp()">+</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScoreEditor",

  props: {
    score: {
      type: Number,
      required: false
    }
  },

  methods: {
    scoreDown() {
      if (this.score > 0) {
        const adjustedScore = this.score - 1
        this.adjustedScore = this.adjustedScore - 1

        this.$emit("changeScore", adjustedScore)
      }
    },

    scoreUp() {
      const adjustedScore = this.score + 1

      this.$emit("changeScore", adjustedScore)
    }
  }
}
</script>

<style lang="scss" scoped>
  .single-score {
    max-width: 120px;
    text-align: center;

    &__inner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid #ebebeb;
      border-radius: 6px;
    }

    &__button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 30px;
      font-size: 28px;
    }
    
    &__value {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0 12px;
      padding: 0;
      width: 40px;
      font-size: 28px;
    }
  }
</style>
