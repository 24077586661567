<template>
  <section>
    <v-tabs v-model="active">
      <v-tab :ripple="{ class: 'accent--text' }">Match Info</v-tab>

      <v-tab-item px-0 class="main-body">
        <h3 class="subheading">
          {{ fixtureData.teamData[0].teamName }} -
          {{ fixtureData.teamData[1].teamName }}
          <i>{{ fixtureData.kickOffUTC | moment("ddd DD MMM YYYY") }}</i>
        </h3>

        <v-layout row class="published-wrap">
          <v-flex>
            <div class="published-row">
              <label class="label published-row__label" for="published"
                >Published</label
              >
              <v-checkbox
                id="published"
                v-model="fixtureData.published"
                @change="updateData()"
              ></v-checkbox>
            </div>
          </v-flex>
        </v-layout>

        <template
          v-if="
            (modules.default.StreamAMGIntegration ||
              modules.default.Streamline) &&
            !modules.default.LFCExclusive
          "
        >
          <h3 class="subheading">Streaming Integration</h3>
          <section class="stream-editor section-container">
            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="stream-enabled"
                  >Show Streaming Options (Web)</label
                >
                <v-checkbox
                  id="stream-enabled"
                  v-model="assets.enableStream"
                  @change="updateData()"
                ></v-checkbox>
              </v-flex>
              <v-flex mt-4 class="half">
                <p>
                  <i
                    >Web only - enabling this will show the streaming options in
                    the match centre</i
                  >
                </p>
              </v-flex>
            </v-layout>
          </section>
        </template>

        <h3 v-if="!modules.default.LFCExclusive" class="subheading">
          Match Centre Enable
        </h3>
        <section
          v-if="!modules.default.LFCExclusive"
          class="fixture-editor section-container"
        >
          <v-layout row class="section-divider">
            <v-flex mr-5 class="half">
              <label class="label" for="match-centre-enabled"
                >Show Match Centre</label
              >
              <v-checkbox
                id="match-centre-enabled"
                v-model="assets.enableMatchCentre"
                @change="updateData()"
              ></v-checkbox>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                <i
                  >Web only - enabling this will show the match centre button in
                  any match listing</i
                >
              </p>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-5 class="half">
              <label class="label" for="external-match-centre"
                >External Match Centre URL (optional)</label
              >
              <v-text-field
                id="external-match-centre"
                v-model="assets.externalMatchCentre"
                solo
                flat
                @change="updateData()"
              ></v-text-field>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                <i
                  >The external Match Centre URL can be used to override the
                  location of the match centre.</i
                >
              </p>
              <p>
                <i
                  >On web you are still required to enable the match centre for
                  the button to display</i
                >
              </p>
            </v-flex>
          </v-layout>
        </section>

        <template v-if="betaFeatures.default.MatchdayTakeover">
          <h3 class="subheading">Matchday Takeover</h3>

          <template v-if="isCustom">
            <p class="notice">
              Matchday Takeovers are not currently available for custom fixtures
            </p>
          </template>

          <template v-else>
            <section class="fixture-editor section-container">
              <v-layout row class="section-divider">
                <v-flex mr-5 class="half">
                  <label class="label" for="match-centre-enabled"
                    >Enable Matchday Takeover for this fixture</label
                  >
                  <v-checkbox
                    id="match-centre-enabled"
                    v-model="assets.enableMatchdayTakeover"
                    @change="updateData()"
                  ></v-checkbox>
                </v-flex>
                <v-flex mt-4 class="half">
                  <p><i></i></p>
                </v-flex>
              </v-layout>

              <v-layout row class="section-divider">
                <v-flex mr-5 class="half">
                  <label class="label" for="match-kickoff"
                    >Come on at (as UTC)</label
                  >
                  <v-dialog
                    ref="matchdayTakeoverStartModal"
                    v-model="matchdayTakeoverStartModal"
                    :return-value.sync="assets.matchdayTakeoverStartAt"
                    persistent
                    lazy
                    full-width
                    width="290px"
                  >
                    <v-text-field
                      id="matchday-takeover-start-time"
                      slot="activator"
                      v-model="assets.matchdayTakeoverStartAt"
                      prepend-inner-icon="event"
                      solo
                      flat
                      clearable
                    ></v-text-field>
                    <v-time-picker
                      v-model="assets.matchdayTakeoverStartAt"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        flat
                        color="primary"
                        @click="
                          assets.matchdayTakeoverStartAt = null;
                          matchdayTakeoverStartModal = false;
                        "
                        >Cancel</v-btn
                      >
                      <v-btn
                        flat
                        color="primary"
                        @click="
                          $refs.matchdayTakeoverStartModal.save(
                            assets.matchdayTakeoverStartAt
                          );
                          updateMTOStart();
                        "
                        >OK</v-btn
                      >
                    </v-time-picker>
                  </v-dialog>
                </v-flex>
              </v-layout>

              <v-layout row>
                <v-flex mr-5 class="half">
                  <label class="label" for="match-kickoff"
                    >Finish at (as UTC)</label
                  >
                  <v-dialog
                    ref="matchdayTakeoverFinishModal"
                    v-model="matchdayTakeoverFinishModal"
                    :return-value.sync="assets.matchdayTakeoverFinishAt"
                    persistent
                    lazy
                    full-width
                    width="290px"
                  >
                    <v-text-field
                      id="matchday-takeover-finish-time"
                      slot="activator"
                      v-model="assets.matchdayTakeoverFinishAt"
                      prepend-inner-icon="event"
                      solo
                      flat
                      clearable
                    ></v-text-field>
                    <v-time-picker
                      v-model="assets.matchdayTakeoverFinishAt"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        flat
                        color="primary"
                        @click="
                          assets.matchdayTakeoverFinishAt = null;
                          matchdayTakeoverFinishModal = false;
                        "
                        >Cancel</v-btn
                      >
                      <v-btn
                        flat
                        color="primary"
                        @click="
                          $refs.matchdayTakeoverFinishModal.save(
                            assets.matchdayTakeoverFinishAt
                          );
                          updateMTOEnd();
                        "
                        >OK</v-btn
                      >
                    </v-time-picker>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </section>
          </template>
        </template>

        <h3 v-if="!modules.default.LFCExclusive" class="subheading">Notices</h3>

        <section v-if="!modules.default.LFCExclusive" class="section-container">
          <v-layout row>
            <v-flex ml-2 class="half">
              <p v-if="fixtureData.AdditionalInfo">
                Additional info from Opta:
                <i>{{ fixtureData.AdditionalInfo }}</i>
              </p>
              <p v-else>No Additional info from Opta</p>
            </v-flex>
            <v-flex ml-2 class="half">
              <p>
                <i
                  >Your custom info will be surfaced on the match cards. This
                  will overwrite any additional info Opta is providing. This can
                  be html to allow for links</i
                >
              </p>
              <label class="label" for="custom-info">Custom info</label>
              <v-text-field
                id="custom-info"
                v-model="assets.customInfo"
                solo
                flat
                @change="updateData()"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </section>

        <h3 v-if="!clientRestrictions" class="subheading">Call to actions</h3>

        <section v-if="!clientRestrictions" class="section-container">
          <h3 class="subheading mt-0">Prematch</h3>

          <v-layout row class="section-divider">
            <v-flex mr-5 class="half">
              <label class="label" for="info-url">Information URL</label>
              <v-text-field
                id="info-url"
                v-model="assets.matchDayInfoUrl"
                solo
                flat
                @change="updateData()"
              ></v-text-field>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                <i
                  >A link to general information which will be displayed
                  prematch. This can be used for streaming info or matchday
                  info, or any other way you want</i
                >
              </p>
            </v-flex>
          </v-layout>

          <v-layout row class="section-divider">
            <v-flex mr-5 class="half">
              <label class="label" for="ticket-url">Ticket URL</label>
              <v-text-field
                id="ticket-url"
                v-model="assets.ticketUrl"
                solo
                flat
                @change="updateData()"
              ></v-text-field>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                <i
                  >A link to information on ticketing which will be displayed
                  prematch</i
                >
              </p>
            </v-flex>
          </v-layout>

          <v-layout row class="section-divider">
            <v-flex mr-5 class="half">
              <label class="label" for="match-report">Hospitality URL</label>
              <v-text-field
                id="hospitality-url"
                v-model="assets.hospitalityUrl"
                solo
                flat
                @change="updateData()"
              ></v-text-field>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                <i
                  >A link to information on hospitality which will be displayed
                  prematch</i
                >
              </p>
            </v-flex>
          </v-layout>

          <h3 class="subheading">Postmatch</h3>

          <v-layout row class="section-divider">
            <v-flex mr-5 class="half">
              <label class="label" for="match-report">Match Report</label>
              <v-text-field
                id="match-report"
                v-model="assets.matchReportUrl"
                solo
                flat
                @change="updateData()"
              ></v-text-field>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                <i
                  >A link to the match report which will be displayed
                  postmatch</i
                >
              </p>
            </v-flex>
          </v-layout>

          <v-layout
            row
            class="section-divider"
            v-if="
              modules.default.StreamAMGIntegration || modules.default.Streamline
            "
          >
            <v-flex mr-5 class="half">
              <label class="label" for="highlights-video-id"
                >Highlights Video ID</label
              >
              <v-text-field
                id="highlights-video-id"
                v-model="assets.highlightsVideoID"
                solo
                flat
                @change="updateData()"
              ></v-text-field>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                <i
                  >The highlights video ID will display a button which will open
                  a video highlight in the video player. If this is entered then
                  the normal highlights link will not display on the web</i
                >
              </p>
            </v-flex>
          </v-layout>

          <v-layout row class="section-divider">
            <v-flex mr-5 class="half">
              <label class="label" for="highlights">Highlights Link</label>
              <v-text-field
                id="highlights"
                v-model="assets.highlights"
                solo
                flat
                @change="updateData()"
              ></v-text-field>
            </v-flex>
            <v-flex mt-4 class="half">
              <p v-if="modules.default.StreamAMGIntegration">
                <i
                  >The highlights link will display a button with the supplied
                  URL. This will either take the user internally or externally
                  hosted page to view match highlights - Please only use this if
                  the highlights aren't available in the StreamAMG platform</i
                >
              </p>

              <p v-else>
                <i
                  >The highlights link will display a button which can take a
                  user internally or externally to view match highlights</i
                >
              </p>
            </v-flex>
          </v-layout>

          <h3 class="subheading">Misc</h3>

          <v-layout row>
            <v-flex mr-5 class="half">
              <label class="label" for="highlights"
                >Match Hashtag (Do not enter the hash #)</label
              >
              <v-text-field
                id="hashtag"
                v-model="assets.hashtag"
                solo
                flat
                @change="updateData()"
              ></v-text-field>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                <i
                  >The match hashtag is displayed in the match centre and links
                  through to X</i
                >
              </p>
            </v-flex>
          </v-layout>
        </section>

        <h3 class="subheading">Match Preview</h3>

        <section class="fixture-preview">
          <div class="fixture-preview__team">
            <img
              :src="`${imageURL}/${fixtureData.teamData[0].teamCrest}`"
              height="100"
              class="fixture-preview__crest"
            />
            <h6 class="fixture-preview__team-name">
              {{ fixtureData.teamData[0].teamName }}
            </h6>
          </div>

          <div class="fixture-preview__team">
            <img
              :src="`${imageURL}/${fixtureData.teamData[1].teamCrest}`"
              height="100"
              class="fixture-preview__crest"
            />
            <h6 class="fixture-preview__team-name">
              {{ fixtureData.teamData[1].teamName }}
            </h6>
          </div>

          <time v-if="customData.matchDate" class="fixture-preview__date"
            >{{ customData.matchDate }}
            <span
              v-if="customData.matchKickoff"
              class="fixture-preview__kickoff"
              >{{ customData.matchKickoff }}</span
            ></time
          >

          <time v-else class="fixture-preview__date"
            >{{ fixtureData.kickOffUTC | moment("DD/MM/YY") }}
            <span class="fixture-preview__kickoff">{{
              fixtureData.kickOffUTC | moment("kk:mm (Z)")
            }}</span>
          </time>
        </section>

        <h3 class="subheading">Design</h3>

        <section class="section-container">
          <v-layout row>
            <ImageUploader
              :title="'Featured Image'"
              :mediaLibraryID="assets.featuredImageID"
              :widthOptionEnabled="false"
              @update="updateFeaturedImage($event)"
            />
          </v-layout>
        </section>

        <template v-if="isCustom">
          <h3 class="subheading">Match Period</h3>
          <section class="section-container">
            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="matchPeriod">Match Period</label>

                <v-select
                  v-if="sportType === 'football'"
                  id="matchPeriod"
                  :items="footballPeriodOptions"
                  v-model="fixtureData.period"
                  solo
                  flat
                  @change="updateData()"
                >
                </v-select>

                <v-select
                  v-else-if="sportType === 'rugby-league'"
                  id="matchPeriod"
                  :items="rugbyLeagueStatusOptions"
                  v-model="fixtureData.status"
                  solo
                  flat
                  @change="updateData()"
                >
                </v-select>
              </v-flex>
              <v-flex mt-4 class="half">
                <p>
                  <i
                    >Custom matches need periods updating to render correctly</i
                  >
                </p>
              </v-flex>
            </v-layout>
          </section>
        </template>

        <h3 class="subheading">Match Kickoff</h3>
        <section class="section-container">
          <v-layout row class="section-divider">
            <v-flex mr-5 class="half">
              <label class="label" for="match-kickoff"
                >Match Kickoff (as UTC)</label
              >

              <datetime
                :disabled="!isCustom"
                v-model="fixtureData.kickOffUTC"
                type="datetime"
                zone="UTC"
              ></datetime>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                <i
                  >This is the UTC kickoff date and time that match will be
                  played at</i
                >
              </p>
              <p>
                <i
                  >This cannot be changed for Opta imported matches and you must
                  contact Opta if you want it updated.</i
                >
              </p>
              <p>
                <a class="help" :href="utcHelpLink" target="_blank"
                  >Help converting to UTC</a
                >
              </p>
            </v-flex>
          </v-layout>

          <v-layout row class="section-divider">
            <v-flex mr-5 class="half">
              <label class="label" for="display-date"
                >Override Date (optional)</label
              >
              <v-text-field
                id="display-date"
                v-model="customData.matchDate"
                solo
                flat
                required
                @change="updateData()"
              ></v-text-field>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                <i
                  >Warning - This field should be used carefully. If you use a
                  custom date this will prevent any proper date/time data being
                  rendered.</i
                >
              </p>
              <p>
                <i
                  >It allows for any data to be set and rendered. This should
                  only be used temporarily. If your Opta kickoff date is
                  incorrect please inform your account manager with Opta.</i
                >
              </p>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-5 class="half">
              <label class="label" for="display-kickoff"
                >Override Time (optional)</label
              >
              <v-text-field
                id="display-kickoff"
                v-model="customData.matchKickoff"
                solo
                flat
                @change="updateData()"
              ></v-text-field>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                <i
                  >Warning - This field should be used carefully. If you use a
                  custom time this will prevent any proper date/time data being
                  rendered.</i
                >
              </p>
              <p>
                <i
                  >It allows for any data to be set and rendered. This should
                  only be used temporarily. If your opta kickoff time is
                  incorrect please inform your account manager with Opta.</i
                >
              </p>
            </v-flex>
          </v-layout>
        </section>

        <h3 class="subheading">Teams</h3>

        <section class="fixture-editor section-container">
          <v-layout row v-if="isCustom">
            <v-flex mr-2 class="super-narrow">
              <label class="label" for="homeOrAway">Home or Away</label>
              <v-select
                id="homeOrAway"
                :items="homeOrAwayOptions"
                v-model="fixtureData.homeOrAway"
                solo
                flat
                @change="updateData()"
              >
              </v-select>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-2>
              <label class="label" for="home-team">Home Team</label>
              <v-text-field
                :readonly="!isCustom"
                id="home-team"
                v-model="fixtureData.teamData[0].teamName"
                solo
                flat
                required
                @change="updateData()"
              ></v-text-field>

              <ImageUploader
                v-if="isCustom"
                :title="'Home Crest'"
                :mediaLibraryID="fixtureData.teamData[0].teamCrest_CustomID"
                :widthOptionEnabled="false"
                @update="addHomeCrest($event)"
              />
            </v-flex>

            <v-flex ml-2>
              <label class="label" for="away-team">Away Team</label>
              <v-text-field
                :readonly="!isCustom"
                id="away-team"
                v-model="fixtureData.teamData[1].teamName"
                solo
                flat
                required
                @change="updateData()"
              ></v-text-field>

              <ImageUploader
                v-if="isCustom"
                :title="'Away Crest'"
                :mediaLibraryID="fixtureData.teamData[1].teamCrest_CustomID"
                :widthOptionEnabled="false"
                @update="addAwayCrest($event)"
              />
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-2 class="super-narrow">
              <label class="label" for="homeOrAwayCustom"
                >Home, Away or Neutral</label
              >
              <v-select
                id="homeOrAwayCustom"
                :items="homeOrAwayCustomOptions"
                v-model="assets.homeOrAway_Custom"
                solo
                flat
                @change="updateData()"
              >
              </v-select>
            </v-flex>
          </v-layout>
        </section>

        <h3 class="subheading">Match Info</h3>

        <section class="fixture-editor section-container">
          <v-layout row>
            <v-flex mr-2>
              <ImageUploader
                v-if="isCustom"
                :title="'Competition Icon'"
                :mediaLibraryID="fixtureData.competitionIcon_Custom"
                :widthOptionEnabled="false"
                @update="addCompetitionIcon($event)"
              />
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-2>
              <label class="label" for="competition">Competition</label>
              <v-text-field
                :readonly="!isCustom"
                id="competition"
                v-model="fixtureData.competitionName"
                solo
                flat
                required
                @change="updateData()"
              ></v-text-field>
            </v-flex>

            <v-flex ml-2>
              <label class="label" for="venue">Venue</label>
              <v-text-field
                :readonly="!isCustom"
                id="venue"
                v-model="fixtureData.venue"
                solo
                flat
                required
                @change="updateData()"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row v-if="!isCustom">
            <v-flex mr-5 class="half">
              <label class="label" for="venueCustom">Custom Venue</label>
              <v-text-field
                id="venueCustom"
                v-model="assets.venueCustom"
                solo
                flat
                required
                @change="updateData()"
              ></v-text-field>
            </v-flex>
            <v-flex mt-4 class="half">
              <p><i>This will override opta provided data on the venue</i></p>
            </v-flex>
          </v-layout>

          <v-layout row v-if="isCustom">
            <v-flex mr-2>
              <label class="label" for="match-official">Match Official</label>
              <v-text-field
                :readonly="!isCustom"
                id="match-official"
                v-model="fixtureData.matchOfficial_Custom"
                solo
                flat
                required
                @change="updateData()"
              ></v-text-field>
            </v-flex>

            <v-flex ml-2>
              <label class="label" for="attendance">Attendance</label>
              <v-text-field
                :readonly="!isCustom"
                id="attendance"
                v-model="fixtureData.attendance_Custom"
                solo
                flat
                required
                @change="updateData()"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </section>

        <template v-if="betaFeatures.default.WhereToStream">
          <h3 class="subheading">Where to Watch/Listen?</h3>

          <section class="fixture-editor section-container">
            <v-layout row class="section-divider">
              <v-flex mr-5 class="half">
                <ImageUploader
                  :title="'Watch Logo (Web)'"
                  :mediaLibraryID="
                    assets.streamingLocationWebWatch
                      ? assets.streamingLocationWebWatch.mediaLibraryID
                      : null
                  "
                  :widthOptionEnabled="false"
                  @update="
                    addStreamingLocationImage(
                      $event,
                      'streamingLocationWebWatch'
                    )
                  "
                />
              </v-flex>
              <v-flex mt-4 class="half">
                <p>
                  <i
                    >Optional: Upload a brand logo here for indicating to users
                    where a match can be watched at</i
                  >
                </p>
                <p>This will display on the website</p>
              </v-flex>
            </v-layout>

            <v-layout row class="section-divider">
              <v-flex mr-5 class="half">
                <ImageUploader
                  :title="'Listen Logo (Web)'"
                  :mediaLibraryID="
                    assets.streamingLocationWebListen
                      ? assets.streamingLocationWebListen.mediaLibraryID
                      : null
                  "
                  :widthOptionEnabled="false"
                  @update="
                    addStreamingLocationImage(
                      $event,
                      'streamingLocationWebListen'
                    )
                  "
                />
              </v-flex>
              <v-flex mt-4 class="half">
                <p>
                  <i
                    >Optional: Upload a brand logo here for indicating to users
                    where a match can be listened at on the website</i
                  >
                </p>
                <p>This will display on the website</p>
              </v-flex>
            </v-layout>

            <v-layout row class="section-divider">
              <v-flex mr-5 class="half">
                <ImageUploader
                  :title="'Watch Logo (App)'"
                  :mediaLibraryID="
                    assets.streamingLocationAppWatch
                      ? assets.streamingLocationAppWatch.mediaLibraryID
                      : null
                  "
                  :widthOptionEnabled="false"
                  @update="
                    addStreamingLocationImage(
                      $event,
                      'streamingLocationAppWatch'
                    )
                  "
                />
              </v-flex>
              <v-flex mt-4 class="half">
                <p>
                  <i
                    >Optional: Upload a brand logo here for indicating to users
                    where a match can be watched at</i
                  >
                </p>
                <p>This will display on the app</p>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-5 class="half">
                <ImageUploader
                  :title="'Listen Logo (App)'"
                  :mediaLibraryID="
                    assets.streamingLocationAppListen
                      ? assets.streamingLocationAppListen.mediaLibraryID
                      : null
                  "
                  :widthOptionEnabled="false"
                  @update="
                    addStreamingLocationImage(
                      $event,
                      'streamingLocationAppListen'
                    )
                  "
                />
              </v-flex>
              <v-flex mt-4 class="half">
                <p>
                  <i
                    >Optional: Upload a brand logo here for indicating to users
                    where a match can be listened at</i
                  >
                </p>
                <p>This will display on the app</p>
              </v-flex>
            </v-layout>
          </section>
        </template>

        <template v-if="modules.default.Calendar">
          <h3 class="subheading">Calendar Widget Features</h3>
          <section class="section-container">
            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="url">Custom Button Url</label>
                <v-text-field
                  id="url"
                  v-model="calendarWidgetData.buttonLink"
                  solo
                  flat
                  @change="updateData()"
                ></v-text-field>
              </v-flex>
              <v-flex mt-4 class="half">
                <p>
                  Link to find out more information about this match.
                  <span v-if="modules.default.Streamline"
                    >This will override Streamline asset links and can be used
                    to link to the match centre or anywhere else</span
                  >
                </p>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="url">Custom Button Text</label>
                <v-text-field
                  id="url"
                  v-model="calendarWidgetData.buttonText"
                  solo
                  flat
                  @change="updateData()"
                ></v-text-field>
              </v-flex>
              <v-flex mt-4 class="half">
                <p>
                  Text to find out more information about this event.
                  <span v-if="modules.default.Streamline"
                    >This will override Streamline asset links and can be used
                    to link to the match centre or anywhere else</span
                  >
                </p>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="button-icon">Button Icon</label>
                <v-select
                  id="button-icon"
                  :items="icons"
                  item-text="name"
                  item-value="value"
                  v-model="calendarWidgetData.buttonIcon"
                  solo
                  flat
                  @change="updateData()"
                >
                </v-select>
              </v-flex>
              <v-flex mt-4 class="half">
                <p></p>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-5 class="half">
                <ImageUploader
                  :title="'Card background image'"
                  :mediaLibraryID="
                    calendarWidgetData.image
                      ? calendarWidgetData.image.mediaLibraryID
                      : null
                  "
                  :widthOptionEnabled="false"
                  @update="calendarWidgetData.image = $event"
                />
              </v-flex>
              <v-flex mt-4 class="half">
                <p>
                  Optional background image of the calendar item that replaces
                  the default image
                </p>
              </v-flex>
            </v-layout>
          </section>
        </template>

        <template v-if="modules.default.Streamline && modules.default.Calendar">
          <h3 class="subheading">Calendar item - Streamline Info</h3>
          <section class="section-container">
            <v-layout class="row-space" row>
              <v-flex mr-5 class="half">
                <label class="label" for="packagesLink"
                  >Streamline button packages link</label
                >
                <v-text-field
                  id="packagesLink"
                  v-model="streamlineUpgradeLink"
                  solo
                  flat
                  @change="updateData()"
                ></v-text-field>
              </v-flex>
              <v-flex mt-4 class="half">
                <p>
                  If a user does not have the correct entitlements to
                  watch/listen to this match on Streamline and no custom button
                  is selected, a streamline button appears with a CTA to
                  purchase the membership. The default link is defined in the
                  global or settings or it defaults to "/videos/subscriptions".
                  This option overrides the link for this specific item.
                </p>
              </v-flex>
            </v-layout>

            <h3 class="subheading mt-0">Assets</h3>

            <p>
              This only applies for calendar items, such as in the calendar
              widget. The asset picker below will select which Live Stream
              endpoints this Streamline event is tied to.
            </p>

            <v-layout row>
              <v-flex>
                <table class="asset-table">
                  <tr v-for="(asset, index) in assetData" :key="asset.id">
                    <td class="table-data">
                      <v-select
                        v-model="assetData[index].mediaType"
                        readonly
                        item-text="name"
                        item-value="value"
                        :items="[
                          { name: 'Video/Audio Stream', value: 'video' },
                          { name: 'Audio Only Stream', value: 'audio' },
                        ]"
                        solo
                        flat
                        hide-details
                        required
                        @change="updateData()"
                      />
                    </td>
                    <td class="table-data">
                      <live-asset-picker
                        :assetID="assetData[index].assetID"
                        @assetUpdated="updateStreamlineAsset($event, index)"
                        @assetCleared="clearStreamlineAsset(index)"
                      />
                    </td>
                  </tr>
                </table>
              </v-flex>
            </v-layout>
          </section>
        </template>

        <h3 v-if="!modules.default.LFCExclusive" class="subheading">
          Additional Features
        </h3>

        <section v-if="!modules.default.LFCExclusive" class="section-container">
          <v-layout
            v-if="modules.default.AppScorePredictor"
            row
            class="section-divider"
          >
            <v-flex mr-5 class="half">
              <div>
                <label class="label" for="score-predictor-app"
                  >Score Predictor (App)</label
                >
                <v-select
                  id="score-predictor-app"
                  v-model="assets.enableScorePredictor_App"
                  item-text="name"
                  item-value="value"
                  :items="[
                    { name: 'Enable for prematch', value: true },
                    { name: 'Disable', value: false },
                  ]"
                  solo
                  flat
                  @change="updateData()"
                />
              </div>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                <i
                  >The score predictor can be turned on to allow users to share
                  their score predictions for an upcoming match. Once a game has
                  started users will no longer be able to predict the score. If
                  a user has made a score prediction this will continue to be
                  visible and shareable.</i
                >
              </p>
            </v-flex>
          </v-layout>
        </section>

        <template v-if="!isCustom && modules.default.PlayerVoting">
          <h3 class="subheading">Player of the match voting</h3>

          <section
            v-if="voteStatus === 'PRE' || voteStatus === 'OPEN'"
            class="section-container"
          >
            <v-layout row>
              <v-flex mr-2>
                <label class="label" for="vote-closing-date"
                  >Closing date</label
                >
                <v-dialog
                  ref="voteDateModal"
                  v-model="voteDateModal"
                  :return-value.sync="assets.voteClosingDate"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <v-text-field
                    id="vote-closing-date"
                    slot="activator"
                    v-model="assets.voteClosingDate"
                    prepend-inner-icon="event"
                    solo
                    flat
                    clearable
                  ></v-text-field>
                  <v-date-picker v-model="assets.voteClosingDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="voteDateModal = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      flat
                      color="primary"
                      @click="
                        $refs.voteDateModal.save(assets.voteClosingDate);
                        updateVoteUTC();
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>

                <v-btn
                  v-if="voteStatus === 'OPEN'"
                  color="accent"
                  @click="closeVoting()"
                  >Close vote early and generate results</v-btn
                >

                <p v-else-if="voteStatus === 'PRE'">
                  You must have a closing date and time for voting to work
                </p>
              </v-flex>

              <v-flex mr-2>
                <label class="label" for="vote-closing-time"
                  >Closing time (as UTC)
                  <a class="help" :href="utcHelpLink" target="_blank"
                    >Help converting to UTC</a
                  ></label
                >
                <v-dialog
                  ref="voteTimeModal"
                  v-model="voteTimeModal"
                  :return-value.sync="assets.voteClosingTime"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <v-text-field
                    id="vote-closing-time"
                    slot="activator"
                    v-model="assets.voteClosingTime"
                    prepend-inner-icon="access_time"
                    solo
                    flat
                    clearable
                  ></v-text-field>
                  <v-time-picker
                    v-if="voteTimeModal"
                    v-model="assets.voteClosingTime"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="voteTimeModal = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      flat
                      color="primary"
                      @click="
                        $refs.voteTimeModal.save(assets.voteClosingTime);
                        updateVoteUTC();
                      "
                      >OK</v-btn
                    >
                  </v-time-picker>
                </v-dialog>
              </v-flex>
            </v-layout>
          </section>

          <section
            v-else-if="voteStatus === 'CLOSED'"
            class="section-container"
          >
            <v-layout row>
              <v-flex mr-2>
                <p>This vote is now closed. You can now generate the results</p>

                <v-btn color="accent" @click="closeVoting()"
                  >Close vote and generate results</v-btn
                >
              </v-flex>
            </v-layout>
          </section>

          <section
            v-else-if="voteStatus === 'COMPLETE'"
            class="section-container"
          >
            <v-layout row>
              <v-flex mr-2>
                <p>Results have been generated</p>
                <p>
                  <b>Total votes: {{ voteResults.totalVotesCast }}</b>
                </p>

                <ol>
                  <li
                    v-for="result in voteResults.matchPlayers"
                    :key="`vote-result-${result.playerID}`"
                    class="vote__result"
                  >
                    <p class="vote__name">
                      <b class="vote__shirtnumber">{{ result.shirtNumber }} </b>
                      <template v-if="result.knownName">{{
                        result.knownName
                      }}</template>
                      <template v-else
                        >{{ result.firstName }} {{ result.surname }}</template
                      >
                    </p>

                    <span class="vote__count-wrap">
                      <span class="vote__count-label"
                        >{{ result.votes }} votes /
                        {{ result.votePercent }}%</span
                      >
                      <span
                        class="vote__count"
                        :style="`width: ${result.votePercent}%;`"
                      ></span>
                    </span>
                  </li>
                </ol>
              </v-flex>
            </v-layout>
          </section>
        </template>

        <v-layout row>
          <v-flex>
            <v-btn color="error" @click="deleteCustomFixture()"
              >Remove custom fixture</v-btn
            >
          </v-flex>
        </v-layout>
      </v-tab-item>

      <v-tab
        v-if="this.dataType === 'custom'"
        :ripple="{ class: 'accent--text' }"
        >Custom Match Details</v-tab
      >

      <v-tab-item v-if="this.dataType === 'custom'" px-0 class="main-body">
        <FixtureDetails
          :teamData="fixtureData.teamData"
          @updateScore="updateScore($event)"
          @updateLineup="updateLineup($event)"
        />
      </v-tab-item>

      <template v-if="modules.default.CustomCommentary">
        <v-tab :ripple="{ class: 'accent--text' }">Min by Min</v-tab>

        <v-tab-item px-0 class="main-body">
          <min-by-min
            :seasonID="seasonID"
            :teamID="teamID"
            :matchID="matchID"
            :matchUTC="fixtureData.kickOffUTC"
          />
        </v-tab-item>
      </template>
    </v-tabs>

    <StickyFooter
      v-if="active === 0"
      :errorMessage="errorMessage"
      :status="status"
      @buttonClicked="post()"
      :allowPreview="
        modules.default.LFCExclusive
          ? false
          : fixtureData.teamSlug
          ? true
          : false
      "
      @previewRequested="previewFixture()"
    />
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ImageUploader from "@/components/Library/ImageUploader";
import FixtureDetails from "@/components/Fixtures/FixtureDetails";
import MinByMin from "@/components/Fixtures/MinByMin";
import StickyFooter from "@/components/Library/StickyFooter";
import LiveAssetPicker from "@/components/Library/LiveAssetPicker";

export default {
  name: "FixtureEditor",

  components: {
    ImageUploader,
    FixtureDetails,
    MinByMin,
    StickyFooter,
    LiveAssetPicker,
  },

  props: {
    seasonID: {
      type: Number,
    },
    teamID: {
      type: String,
    },
    matchID: {
      type: String,
    },
    dataType: {
      type: String,
      default: "opta",
    },
  },

  data: () => ({
    clientRestrictions: process.env.VUE_APP_CLUB_NAME == "LFC",
    api: `${process.env.VUE_APP_BASEURL}/fixtures`,
    sportType: process.env.VUE_APP_SPORT,
    imageURL: `${process.env.VUE_APP_IMAGEURL}`,
    modules: null,
    betaFeatures: null,
    active: null,
    status: "saved",
    homeOrAwayOptions: ["Home", "Away"],
    homeOrAwayCustomOptions: ["Home", "Away", "Neutral"],
    footballPeriodOptions: [
      "PreMatch",
      "Live",
      "FirstHalf",
      "HalfTime",
      "SecondHalf",
      "FullTime",
      "Postponed",
      "Abandoned",
    ],
    rugbyLeagueStatusOptions: ["PreMatch", "Result"],
    errorMessage: "",
    dateModal: false,
    timeModal: false,
    voteDateModal: false,
    voteTimeModal: false,
    matchdayTakeoverStartModal: false,
    matchdayTakeoverFinishModal: false,
    matchdayTakeoverStartAtUTC: null,
    matchdayTakeoverFinishAtUTC: null,
    isCustom: false,
    utcHelpLink: "https://savvytime.com/converter/bst-to-utc/",
    updatingVote: false,
    voteStatus: null,
    voteResults: null,
    assets: {
      ticketUrl: null,
      matchReportUrl: null,
      highlights: null,
      highlightsVideoID: null,
      matchDayInfoUrl: null,
      hospitalityUrl: null,
      hashtag: null,
      featuredImageID: null,
      enableMatchCentre: false,
      externalMatchCentre: "",
      enableStream: false,
      voteClosingUTC: null,
      voteClosingTime: null,
      voteClosingDate: null,
      customInfo: null,
      enableScorePredictor_App: false,
      homeOrAway_Custom: null,
      enableMatchdayTakeover: null,
      matchdayTakeoverStartAt: null,
      matchdayTakeoverFinishAt: null,
      venueCustom: null,
    },
    customData: {
      matchDate: null,
      matchKickoff: null,
    },
    fixtureData: {
      kickOffUTC: null,
      matchDate: null,
      matchKickoff: null,
      competitionName: null,
      venue: null,
      homeOrAway: null,
      matchOfficial_Custom: null,
      attendance_Custom: null,
      competitionIcon_Custom: null,
      period: "PreMatch",
      status: "PreMatch",
      published: 0,
      teamData: [
        {
          teamName: null,
          teamCrest: null,
          teamCrest_CustomID: null,
          ninetyScore: 0,
          score: 0,
          teamPlayers: [],
        },
        {
          teamName: null,
          teamCrest: null,
          teamCrest_CustomID: null,
          ninetyScore: 0,
          score: 0,
          teamPlayers: [],
        },
      ],
      teamSlug: null,
      seasonSlug: null,
    },
    assetData: [
      {
        id: "asset-1",
        mediaType: "video",
        assetID: "",
      },
      {
        id: "asset-2",
        mediaType: "audio",
        assetID: "",
      },
    ],
    streamlineUpgradeLink: null,
    calendarWidgetData: {
      buttonText: "",
      buttonLink: "",
      buttonIcon: "",
      image: "",
    },
    icons: [
      {
        name: "None",
        value: null,
      },
      {
        name: "Article",
        value: "Article",
      },
      {
        name: "Audio",
        value: "Audio",
      },
      {
        name: "Ball",
        value: "Ball",
      },
      {
        name: "Gallery",
        value: "Camera",
      },
      {
        name: "Live",
        value: "Live",
      },
      {
        name: "Quiz",
        value: "Quiz",
      },
      {
        name: "Tickets",
        value: "Tickets",
      },
      {
        name: "Video",
        value: "Video",
      },
    ],
  }),

  // Direct route blocking unless comes from route with data
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.seasonID && vm.teamID && vm.matchID) {
        next();
      } else {
        next("/fixtures");
      }
    });
  },

  mounted() {
    this.modules = require("../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
    this.betaFeatures = require("../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/beta.js");

    console.log("dataType", this.dataType);
    if (this.dataType === "custom") {
      this.isCustom = true;
    } else {
      this.isCustom = false;
    }

    if (this.matchID != "create") {
      this.fetchFixture();
      this.fetchAssets();
      this.checkVote();
    }

    console.groupCollapsed("Fixture Edit mounted");
    console.log("seasonID:", this.seasonID);
    console.log("teamID:", this.teamID);
    console.log("matchID:", this.matchID);
    console.groupEnd();
  },

  methods: {
    post() {
      this.errorMessage = "";

      if (this.matchID === "create") {
        console.log("Create new post");
        if (
          this.fixtureData.kickOffUTC &&
          this.fixtureData.teamData[0].teamName &&
          this.fixtureData.teamData[1].teamName
        ) {
          this.addCustomFixture();
        } else {
          this.errorMessage = "Please save match date and team names";
          this.status = "needsSaving";
          alert("Not enough data");
        }
      } else if (this.dataType === "custom") {
        if (
          this.fixtureData.kickOffUTC &&
          this.fixtureData.teamData[0].teamName &&
          this.fixtureData.teamData[1].teamName
        ) {
          console.log("Save custom fixture");
          this.updateCustom();
          this.updateAssets();
        } else {
          this.errorMessage = "Please save match date and team names";
          this.status = "needsSaving";
          alert("Not enough data");
        }
      } else {
        console.log("Save opta fixture");
        this.updateFixture();
        this.updateAssets();
      }
    },

    updateKickoff() {
      this.utcHelpLink = `https://savvytime.com/converter/bst-to-utc/${moment(
        this.fixtureData.matchDate
      ).format("MMM-D-YYYY")}`;

      // let matchDate = moment(this.fixtureData.matchDate).format('YYYY-MM-DD')
      // let matchKickoff = moment(this.fixtureData.matchKickoff, 'HH:mm').format('HH:mm:ss.SSSZ')

      // if (this.fixtureData.matchDate !== 'Invalid date' && this.fixtureData.matchKickoff !== 'Invalid date') {
      //   this.fixtureData.kickOffUTC = moment(matchDate + ' ' + matchKickoff).utc().format()

      //   // let testdate = moment(this.fixtureData.kickOffUTC).format('YYYY-MM-DD HH:mm:ss')

      //   console.group()
      //   console.log('User input matchDate', this.fixtureData.matchDate)
      //   console.log('User input matchKickoff', this.fixtureData.matchKickoff)
      //   console.log('Converted matchKickoff', matchKickoff)
      //   console.log('Converted matchDate', matchDate)
      //   console.log('Save to db as utc', this.fixtureData.kickOffUTC)
      //   // console.log('Display local', testdate)
      //   console.groupEnd()

      //   this.updateData()
      // }

      console.log("Update kickoff");

      const matchDate = moment(this.fixtureData.matchDate).format("YYYY-MM-DD");
      const matchKickoff = moment(this.fixtureData.matchKickoff, "HH:mm").format(
        "HH:mm:ss.SSSZ"
      );

      if (
        this.fixtureData.matchDate !== "Invalid date" ||
        this.fixtureData.matchKickoff !== "Invalid date"
      ) {
        this.fixtureData.kickOffUTC = moment(matchDate + " " + matchKickoff)
          .utc()
          .format();

        const testdate = moment(this.fixtureData.kickOffUTC).format(
          "YYYY-MM-DD HH:mm:ss"
        );

        console.group();
        console.log("User input matchDate", this.fixtureData.matchDate);
        console.log("User input matchKickoff", this.fixtureData.matchKickoff);
        console.log("Converted", matchKickoff);
        console.log("Save to db as utc", this.fixtureData.kickOffUTC);
        console.log("Display local", testdate);
        console.groupEnd();

        this.updateData();
      }
    },

    updateVoteUTC() {
      console.log("updateVoteUTC");
      const voteClosingDate = moment(this.assets.voteClosingDate).format(
        "YYYY-MM-DD"
      );
      const voteClosingTime = moment(this.assets.voteClosingTime, "HH:mm").format(
        "HH:mm:ss.SSSZ"
      );

      if (
        this.assets.voteClosingDate !== "Invalid date" &&
        this.assets.voteClosingTime !== "Invalid date"
      ) {
        this.assets.voteClosingUTC = moment(
          voteClosingDate + " " + voteClosingTime
        )
          .utc()
          .format();

        const testdate = moment(this.assets.voteClosingUTC).format(
          "YYYY-MM-DD HH:mm:ss"
        );

        console.group();
        console.log("User input", this.assets.voteClosingDate);
        console.log("Converted", voteClosingTime);
        console.log("Save to db as utc", this.assets.voteClosingUTC);
        console.log("Display local", testdate);
        console.groupEnd();

        this.updateData();
      }
    },

    updateMTOStart() {
      console.log("updateMTOStart");
      const date = moment(this.fixtureData.matchDate).format("YYYY-MM-DD");
      const time = moment(this.assets.matchdayTakeoverStartAt, "HH:mm").format(
        "HH:mm:ss.SSSZ"
      );

      // if (this.assets.voteClosingDate !== 'Invalid date' && this.assets.voteClosingTime !== 'Invalid date') {
      this.matchdayTakeoverStartAtUTC = moment(date + " " + time)
        .utc()
        .format();

      // let testdate = moment(this.assets.voteClosingUTC).format('YYYY-MM-DD HH:mm:ss')

      // console.group()
      // console.log('User input', this.assets.voteClosingDate)
      // console.log('Converted', voteClosingTime)
      // console.log('Save to db as utc', this.assets.voteClosingUTC)
      // console.log('Display local', testdate)
      // console.groupEnd()

      // this.updateData()
      // }
    },

    updateMTOEnd() {
      console.log("updateMTOEnd");
      const date = moment(this.fixtureData.matchDate).format("YYYY-MM-DD");
      const time = moment(this.assets.matchdayTakeoverFinishAt, "HH:mm").format(
        "HH:mm:ss.SSSZ"
      );

      // if (this.assets.voteClosingDate !== 'Invalid date' && this.assets.voteClosingTime !== 'Invalid date') {
      this.matchdayTakeoverFinishAtUTC = moment(date + " " + time)
        .utc()
        .format();

      // let testdate = moment(this.assets.voteClosingUTC).format('YYYY-MM-DD HH:mm:ss')

      // console.group()
      // console.log('User input', this.assets.voteClosingDate)
      // console.log('Converted', voteClosingTime)
      // console.log('Save to db as utc', this.assets.voteClosingUTC)
      // console.log('Display local', testdate)
      // console.groupEnd()

      // this.updateData()
      // }
    },

    deleteCustomFixture() {
      this.errorMessage = "";
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/custom/updatesingle?seasonID=${this.seasonID}&teamID=${this.teamID}`,
          headers: { Authorization: jwt },
          data: {
            kickOffUTC: this.fixtureData.kickOffUTC,
            matchID: this.matchID,
            isCustom: true,
            deleted: 1,
          },
        })
          .then((response) => {
            console.log("Deleting custom match:", response);
            this.matchID = response.matchID;
            this.status = "saved";

            this.$router.push({ path: "/fixtures" });
          })
          .catch((e) => {
            console.error("Problem deleting custom match", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem deleting custom match";
          });
      });
    },

    addCustomFixture() {
      this.errorMessage = "";
      this.$store.commit("startLoading");

      let publishedValue;

      if (this.fixtureData.published == true) {
        publishedValue = 1;
      } else {
        publishedValue = 0;
      }

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/custom/add?seasonID=${this.seasonID}&teamID=${this.teamID}`,
          headers: { Authorization: jwt },
          data: {
            kickOffUTC: this.fixtureData.kickOffUTC,
            isCustom: true,
            kickOffDate_Custom: this.customData.matchDate,
            kickOffTime_Custom: this.customData.matchKickoff,
            published: publishedValue,
            period: this.fixtureData.period,
            status: this.fixtureData.status,
            homeOrAway: this.fixtureData.homeOrAway,
            teamData: this.fixtureData.teamData,
            competitionName: this.fixtureData.competitionName,
            venue: this.fixtureData.venue,
            matchOfficial_Custom: this.fixtureData.matchOfficial_Custom,
            attendance_Custom: this.fixtureData.attendance_Custom,
            competitionIcon_Custom: this.fixtureData.competitionIcon_Custom,
          },
        })
          .then((response) => {
            console.log("Saved custom match:", response);
            this.matchID = response.matchID;
            this.status = "saved";

            this.updateAssets();
          })
          .catch((e) => {
            console.error("Problem posting custom match", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem posting custom match";
          });
      });
    },

    addHomeCrest(payload) {
      console.log("Add crest:", payload);

      this.updateData();

      this.fixtureData.teamData[0].teamCrest_CustomID = payload.mediaLibraryID;
    },

    addAwayCrest(payload) {
      console.log("Add crest:", payload);

      this.updateData();

      this.fixtureData.teamData[1].teamCrest_CustomID = payload.mediaLibraryID;
    },

    addCompetitionIcon(payload) {
      console.log("Add competition icon:", payload);

      this.updateData();

      this.fixtureData.competitionIcon_Custom = payload.mediaLibraryID;
    },

    addStreamingLocationImage(payload, target) {
      console.log("Add streaming image:", payload);

      this.updateData();

      this.assets[target] = {
        mediaLibraryID: payload.mediaLibraryID,
        imageKey: payload.imageKey,
      };
    },

    updateFeaturedImage(payload) {
      console.log("Update featured image:", payload);

      this.updateData();

      this.assets.featuredImageID = payload.mediaLibraryID;
    },

    fetchAssets() {
      this.errorMessage = "";
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${this.api}/assets/getsingle?matchID=${this.matchID}`, {
            headers: { Authorization: jwt },
          })
          .then((response) => {
            console.log("fetchAssets response: ", response);

            if (response.data.body) {
              if (response.data.body.ticketUrl) {
                this.assets.ticketUrl = response.data.body.ticketUrl;
              }

              if (response.data.body.hospitalityUrl) {
                this.assets.hospitalityUrl = response.data.body.hospitalityUrl;
              }

              if (response.data.body.matchDayInfoUrl) {
                this.assets.matchDayInfoUrl =
                  response.data.body.matchDayInfoUrl;
              }

              if (response.data.body.hashtag) {
                this.assets.hashtag = response.data.body.hashtag;
              }

              if (response.data.body.featuredImageID) {
                this.assets.featuredImageID =
                  response.data.body.featuredImageID;
              }

              if (response.data.body.matchReportUrl) {
                this.assets.matchReportUrl = response.data.body.matchReportUrl;
              }

              if (response.data.body.highlights) {
                this.assets.highlights = response.data.body.highlights;
              }

              if (response.data.body.highlightsVideoID) {
                this.assets.highlightsVideoID =
                  response.data.body.highlightsVideoID;
              }

              if (response.data.body.enableMatchCentre) {
                this.assets.enableMatchCentre =
                  response.data.body.enableMatchCentre;
              }

              if (response.data.body.externalMatchCentre) {
                this.assets.externalMatchCentre =
                  response.data.body.externalMatchCentre;
              }

              if (response.data.body.enableStream) {
                this.assets.enableStream = response.data.body.enableStream;
              }

              if (response.data.body.enableScorePredictor_App) {
                this.assets.enableScorePredictor_App =
                  response.data.body.enableScorePredictor_App;
              }

              if (response.data.body.voteClosingUTC) {
                this.assets.voteClosingUTC = response.data.body.voteClosingUTC;

                this.assets.voteClosingDate = moment(
                  this.assets.voteClosingUTC
                ).format("YYYY-MM-DD");
                this.assets.voteClosingTime = moment(
                  this.assets.voteClosingUTC
                ).format("HH:mm");
              }

              if (response.data.body.customInfo) {
                this.assets.customInfo = response.data.body.customInfo;
              }

              if (response.data.body.homeOrAway_Custom) {
                this.assets.homeOrAway_Custom =
                  response.data.body.homeOrAway_Custom;
              }

              if (response.data.body.streamingLocationWebWatch) {
                this.assets.streamingLocationWebWatch =
                  response.data.body.streamingLocationWebWatch;
              }

              if (response.data.body.streamingLocationWebListen) {
                this.assets.streamingLocationWebListen =
                  response.data.body.streamingLocationWebListen;
              }

              if (response.data.body.streamingLocationAppWatch) {
                this.assets.streamingLocationAppWatch =
                  response.data.body.streamingLocationAppWatch;
              }

              if (response.data.body.streamingLocationAppListen) {
                this.assets.streamingLocationAppListen =
                  response.data.body.streamingLocationAppListen;
              }

              if (response.data.body.matchdayTakeoverStartAt) {
                this.matchdayTakeoverStartAtUTC =
                  response.data.body.matchdayTakeoverStartAt;

                this.assets.matchdayTakeoverStartAt = moment(
                  this.matchdayTakeoverStartAtUTC
                ).format("HH:mm");
              }

              if (response.data.body.matchdayTakeoverFinishAt) {
                this.matchdayTakeoverFinishAtUTC =
                  response.data.body.matchdayTakeoverFinishAt;

                this.assets.matchdayTakeoverFinishAt = moment(
                  this.matchdayTakeoverFinishAtUTC
                ).format("HH:mm");
              }

              if (response.data.body.enableMatchdayTakeover) {
                this.assets.enableMatchdayTakeover = true;
              }

              if (response.data.body.streamingData) {
                this.assetData = response.data.body.streamingData;
              }

              if (response.data.body.calendarWidgetData) {
                this.calendarWidgetData = response.data.body.calendarWidgetData;
              }

              if (response.data.body.streamlineUpgradeLink) {
                this.streamlineUpgradeLink =
                  response.data.body.streamlineUpgradeLink;
              }

              if (response.data.body.venueCustom) {
                this.assets.venueCustom = response.data.body.venueCustom;
              }
            }

            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem retrieving fetchAssets: ", e);
            this.$store.commit("completeLoading");
            this.errorMessage = "Problem retrieving fetchAssets";
          });
      });
    },

    fetchFixture() {
      this.errorMessage = "";
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(
            `${this.api}/${this.dataType}/getsingle?seasonID=${this.seasonID}&teamID=${this.teamID}&matchID=${this.matchID}`,
            { headers: { Authorization: jwt } }
          )
          .then((response) => {
            console.log("fetchData response: ", this.dataType, response);

            let fixtureResponse;

            if (this.dataType === "opta") {
              fixtureResponse = response.data.body.Items[0];
            } else {
              fixtureResponse = response.data.body;
            }

            this.fixtureData.published = fixtureResponse.published;

            this.fixtureData.kickOffUTC = fixtureResponse.kickOffUTC;

            this.fixtureData.matchDate = moment(
              this.fixtureData.kickOffUTC
            ).format("YYYY-MM-DD");
            this.fixtureData.matchKickoff = moment(
              this.fixtureData.kickOffUTC
            ).format("HH:mm");

            this.fixtureData.period = fixtureResponse.period;
            this.fixtureData.status = fixtureResponse.status;

            this.fixtureData.teamData = fixtureResponse.teamData;

            this.fixtureData.competitionName = fixtureResponse.competitionName;
            this.fixtureData.venue = fixtureResponse.venue;
            this.fixtureData.matchOfficial = fixtureResponse.matchOfficial;
            this.fixtureData.attendance = fixtureResponse.attendance;
            this.fixtureData.homeOrAway = fixtureResponse.homeOrAway;

            this.customData.matchDate = fixtureResponse.kickOffDate_Custom;
            this.customData.matchKickoff = fixtureResponse.kickOffTime_Custom;
            this.fixtureData.matchOfficial_Custom =
              fixtureResponse.matchOfficial_Custom;
            this.fixtureData.attendance_Custom =
              fixtureResponse.attendance_Custom;
            this.fixtureData.competitionIcon_Custom =
              fixtureResponse.competitionIcon_Custom;

            //Preview
            this.fixtureData.teamSlug = fixtureResponse.teamSlug;
            this.fixtureData.seasonSlug = fixtureResponse.seasonSlug;

            this.$store.commit("completeLoading");

            console.info(
              `Webapi: ${process.env.VUE_APP_WEBAPI}/fixtures/opta/getsingle?seasonSlug=${this.fixtureData.seasonSlug}&teamSlug=${this.fixtureData.teamSlug}&matchID=${this.matchID}`
            );
          })
          .catch((e) => {
            console.error("Problem retrieving page: ", e);
            this.$store.commit("completeLoading");
            this.errorMessage = "Problem retrieving page";
          });
      });
    },

    updateCustom() {
      this.status = "saving";

      let publishedValue;

      if (this.fixtureData.published == true) {
        publishedValue = 1;
      } else {
        publishedValue = 0;
      }
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/custom/updatesingle?seasonID=${this.seasonID}&teamID=${this.teamID}`,
          headers: { Authorization: jwt },
          data: {
            kickOffUTC: this.fixtureData.kickOffUTC,
            matchID: this.matchID,
            isCustom: true,
            kickOffDate_Custom: this.customData.matchDate,
            kickOffTime_Custom: this.customData.matchKickoff,
            published: publishedValue,
            period: this.fixtureData.period,
            status: this.fixtureData.status,
            teamData: this.fixtureData.teamData,
            competitionName: this.fixtureData.competitionName,
            venue: this.fixtureData.venue,
            homeOrAway: this.fixtureData.homeOrAway,
            matchOfficial_Custom: this.fixtureData.matchOfficial_Custom,
            attendance_Custom: this.fixtureData.attendance_Custom,
            competitionIcon_Custom: this.fixtureData.competitionIcon_Custom,
          },
        })
          .then((response) => {
            console.log("Saved custom post:", response);
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem saving menu", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem saving menu";
          });
      });
    },

    updateFixture() {
      this.status = "saving";

      let publishedValue;

      if (this.fixtureData.published == true) {
        publishedValue = 1;
      } else {
        publishedValue = 0;
      }
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/opta/updatesingle?seasonID=${this.seasonID}&teamID=${this.teamID}&matchID=${this.matchID}`,
          headers: { Authorization: jwt },
          data: {
            kickOffDate_Custom: this.customData.matchDate,
            kickOffTime_Custom: this.customData.matchKickoff,
            published: publishedValue,
          },
        })
          .then((response) => {
            console.log("Saved post:", response);
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem saving menu", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem saving menu";
          });
      });
    },

    updateAssets() {
      this.status = "saving";
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/assets/insertupdate?matchID=${this.matchID}`,
          headers: { Authorization: jwt },
          data: {
            matchID: this.matchID,
            featuredImageID: this.assets.featuredImageID,
            ticketUrl: this.assets.ticketUrl ? this.assets.ticketUrl : null,
            matchReportUrl: this.assets.matchReportUrl
              ? this.assets.matchReportUrl
              : null,
            highlights: this.assets.highlights ? this.assets.highlights : null,
            highlightsVideoID: this.assets.highlightsVideoID
              ? this.assets.highlightsVideoID
              : null,
            hospitalityUrl: this.assets.hospitalityUrl
              ? this.assets.hospitalityUrl
              : null,
            hashtag: this.assets.hashtag ? this.assets.hashtag : null,
            matchDayInfoUrl: this.assets.matchDayInfoUrl
              ? this.assets.matchDayInfoUrl
              : null,
            enableMatchCentre: this.assets.enableMatchCentre,
            externalMatchCentre: this.assets.externalMatchCentre
              ? this.assets.externalMatchCentre
              : null,
            enableStream: this.assets.enableStream,
            enableScorePredictor_App: this.assets.enableScorePredictor_App,
            voteClosingUTC: this.assets.voteClosingUTC
              ? this.assets.voteClosingUTC
              : null,
            customInfo: this.assets.customInfo ? this.assets.customInfo : null,
            homeOrAway_Custom: this.assets.homeOrAway_Custom
              ? this.assets.homeOrAway_Custom
              : null,
            streamingLocationWebWatch: this.assets.streamingLocationWebWatch
              ? this.assets.streamingLocationWebWatch
              : null,
            streamingLocationWebListen: this.assets.streamingLocationWebListen
              ? this.assets.streamingLocationWebListen
              : null,
            streamingLocationAppWatch: this.assets.streamingLocationAppWatch
              ? this.assets.streamingLocationAppWatch
              : null,
            streamingLocationAppListen: this.assets.streamingLocationAppListen
              ? this.assets.streamingLocationAppListen
              : null,
            //Testing new fields
            enableMatchdayTakeover: this.assets.enableMatchdayTakeover ? 1 : 0,
            matchdayTakeoverStartAt: this.matchdayTakeoverStartAtUTC
              ? this.matchdayTakeoverStartAtUTC
              : "",
            matchdayTakeoverFinishAt: this.matchdayTakeoverFinishAtUTC
              ? this.matchdayTakeoverFinishAtUTC
              : "",
            teamID: this.teamID,
            seasonID: this.seasonID,
            streamingData: this.assetData,
            calendarWidgetData: this.calendarWidgetData,
            streamlineUpgradeLink: this.streamlineUpgradeLink,
            venueCustom: this.assets.venueCustom,
          },
        })
          .then((response) => {
            console.log("Saved assets:", response);
            this.status = "saved";
            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem saving assets", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem saving assets";
          });
      });
    },

    updateScore(payload) {
      console.log("Update Score", payload);

      this.updateData();

      this.fixtureData.teamData[payload.team].score = payload.score;
    },

    updateLineup(payload) {
      console.log("Update lineup", payload);

      this.updateData();

      this.fixtureData.teamData[payload.team].teamPlayers = payload.lineup;
      this.fixtureData.teamData[payload.team].teamEvents = payload.matchEvents;
    },

    updateData() {
      this.status = "needsSaving";
    },

    checkVote() {
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_MATCHAPI_WEB}/motm?matchID=${this.matchID}&seasonID=${this.seasonID}&teamID=${this.teamID}`,
      })
        .then((response) => {
          console.log("Get vote:", response);
          this.voteStatus = response.data.voteStatus;
          this.voteResults = response.data.result;
        })
        .catch((e) => {
          console.error("Problem getting vote", e);
        });
    },

    closeVoting() {
      if (
        confirm(
          "Are you sure you want to close this vote? This cannot be undone"
        )
      ) {
        this.$store.commit("startLoading");

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${process.env.VUE_APP_MATCHAPI}/motm/generateresult`,
            headers: { Authorization: jwt },
            data: {
              matchID: this.matchID,
              teamID: this.teamID,
              seasonID: this.seasonID,
            },
          })
            .then((response) => {
              console.log("Generating vote results:", response);

              this.updatingVote = true;

              if (response.data.success) {
                this.voteStatus = null;
                setTimeout(() => {
                  this.checkVote();
                }, 100);
              } else {
                alert("There was an issue generating your vote results");
              }

              this.$store.commit("completeLoading");
            })
            .catch((e) => {
              console.error("Problem generating results", e);
              this.errorMessage = "Problem generating results";
              this.$store.commit("completeLoading");
            });
        });
      }
    },

    updateStreamlineAsset(payload, index) {
      this.assetData[index].assetID = payload.id;
    },

    clearStreamlineAsset(index) {
      this.assetData[index].assetID = "";
    },

    previewFixture() {
      // console.log('preview', `${process.env.VUE_APP_SITE_DOMAIN}/match/${this.fixtureData.seasonSlug}/${this.fixtureData.teamSlug}/${this.matchID}`)
      window.open(
        `https://${process.env.VUE_APP_PREVIEW_LINK}/match/${this.fixtureData.teamSlug}/${this.fixtureData.seasonSlug}/${this.matchID}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.half {
  width: 50%;
}

.section-divider {
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dfe3e9;
}

.published-wrap {
  display: inline-block;
  padding: 20px 35px 0;
  background-color: #ffffff;
  border: 1px solid #dfe3e9;
  border-radius: 4px;
}

.fixture-preview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 0 30px;
  padding: 35px;
  max-width: 500px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #dfe3e9;
  border-radius: 4px;

  &__team {
    width: 50%;
  }

  &__team-name {
    margin: 10px 0 0;
    font-size: 18px;
    font-weight: 500;
  }

  &__date {
    margin: 20px 0 0;
    width: 100%;
    color: #67247c;
    font-size: 16px;
    font-weight: 500;
  }

  &__kickoff {
    display: block;
    font-weight: 300;
  }
}

.note {
  margin: 20px auto 0;
  font-size: 10px;
  font-style: italic;
}

.published-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 20px;
  width: 200px;

  &__label {
    margin: 0 5px 5px 0;
  }
}

.super-narrow {
  max-width: 200px;
}

.vote {
  &__result {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__shirtnumber {
    padding-right: 4px;
  }

  &__name {
    margin: 0;
  }

  &__count-wrap {
    position: relative;
    width: 400px;
    background-color: #ebebeb;
  }

  &__count-label {
    color: #000;
    font-size: 10px;
    padding: 2px;
  }

  &__count {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: #67247c;
  }
}

.asset-table {
  padding: 16px;
  width: 100%;
  background: #e6e8f1;
  border: 1px solid #dfe3e9;
  border-radius: 4px;
}

.table-data,
.table-data * {
  vertical-align: top;
}
</style>
