<template>
   <v-dialog   
    v-model="isPickerOpen"
    width="580"
    lazy 
    style="display: none">

    <template v-slot:activator="{ on, attrs }">
      <div class="input-container" >
        <div v-bind="attrs" @on="on" @click="clear()" class="clear-container">
          <v-icon class="clear-icon">clear</v-icon>
        </div>

        <div class="input">
          <template v-if="assetID">
            <p class="input-text">{{ assetID }}</p>
          </template>
        </div>
        
        <div v-bind="attrs" @on="on" @click="openPicker()" class="icon-container">
          <v-icon class="search-icon">search</v-icon>
        </div>
      </div>
    </template>

    <slot slot="activator"></slot>
    <v-card v-if="isPickerOpen" class="card">
      <v-icon @click="isPickerOpen = false" class="close-icon">close</v-icon>
      <v-card-title primary-title>Select the asset</v-card-title>
      <v-card-text>
        <section class="match-picker">
          <v-select 
            v-if="assets"
            :items="assets"
            v-model="asset"
            label="Select Asset"
            item-text="attributes.title"
            return-object
            >
          </v-select>

          <p v-else>Loading...</p>

          <v-btn
            class="button"
            color="accent"
            depressed
            :disabled="asset == null"
            @click="updateAsset()"
            >
            Add Live Asset
          </v-btn>
          
        </section>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"


export default {
  name: "LiveAssetPicker",

  props: {
    assetID: {
      type: String,
      required: false
    }
  },

  data: () => ({
    assets: null,
    asset: null,
    selectedAsset: {},
    isPickerOpen: false,
  }),

  created() {
    this.fetchItem();
  },

  methods: {
    fetchItem() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_STREAMLINEAPI}/streams?page[size]=1000`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("response", response)
          if (response.data) {
            this.assets = response.data.data
          }
        })
        .catch(e => {
          console.error("Problem retrieving calendar item", e);
        });
      })
    },

    updateAsset() {
      this.selectedAsset = this.asset
      this.isPickerOpen = false
      this.$emit("assetUpdated", this.selectedAsset)
    },

    clear() {
      this.$emit("assetCleared")
    },

    openPicker() {
      this.isPickerOpen = true
    },
  }
}
</script>

<style scoped lang='scss'>
.hide {
  display: none;
}

.input-container {
  position: relative;
}

.input {
  min-height: 48px;
  min-width: 200px;
  border: 1px solid rgb(223, 227, 233);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 4px 50px 4px 30px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-text {
  max-width: calc(100% - 60px);
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.input-date {
  background-color: peachpuff;
  padding: 2px 6px;
  border-radius: 6px;
  display: inline-block;
}

.input-ids {
  color: #7b6b80;
  opacity: 0.8;
}

.icon-container {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 2px solid grey;
  font-size: 0;
  cursor: pointer;
  padding: 8px;
}

.clear-container {
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  font-size: 0;
  cursor: pointer;
  padding: 0;
}

.search-icon {
  font-size: 18px;
}

.clear-icon {
  font-size: 14px;
}

.card {
  position: relative;
  padding-bottom: 6px;
}

.close-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
  font-size: 16px;
}

.button {
  display: block;
  margin: 6px auto;
}
</style>

