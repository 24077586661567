<template>
  <div class="lineup">
    <p>Lineup</p>

    <div class="player" v-for="(player, index) in teamPlayers" :key="`player-${player.playerID}`">
      <v-btn @click="removePlayer(index)" color="error" fab small class="player__remove-button"><v-icon>remove_circle</v-icon></v-btn>

      <div class="player__detail">
        <v-text-field v-model="player.shirtNumber" class="player__number" solo flat placeholder="00" :readonly="!player.playerEditable"></v-text-field>
        <v-text-field v-model="player.surname" class="player__name" solo flat placeholder="Name" :readonly="!player.playerEditable"></v-text-field>
      </div>

      <v-btn v-if="player.playerEditable" color="success" @click="savePlayer(player, index)">Save Player</v-btn>

      <div class="player__events">
        <span v-for="(goal, index) in player.goals" :key="`${goal.time}-${index}`" class="player__event">Goal: {{ goal.time }}`</span>
        <span v-for="(booking, index) in player.bookings" :key="`${booking.time}-${index}`" class="player__event">{{booking.card}}: {{ booking.time }}`</span>
      </div>

      <div class="player__event-add" v-if="!player.playerEditable">
        <v-text-field class="player__minute" v-model="minute" solo flat placeholder="00"></v-text-field>

        <div class="player__actions">
          <button class="player__action" @click="addEvent('Goal', player, index)">Add Goal</button>
          <button class="player__action" @click="addEvent('Yellow', player, index)">Add Yellow</button>
          <button class="player__action" @click="addEvent('Red', player, index)">Add Red</button>
          <!-- <button class="player__action">Sub Off</button>
          <button class="player__action">Sub On</button> -->
        </div>
      </div>
    </div>

    <v-btn @click="addPlayer()" class="add-player"><v-icon>add_circle</v-icon> Add player</v-btn>
  </div>
</template>

<script>
export default {
  name: "FixtureDetailTeam",

  props: {
    teamPlayers: {
      type: Array,
      required: false
    }
  },

  data:() => ({
    minute: 0,
  }),

  methods: {
    addPlayer() {
      this.teamPlayers.push({
        "shirtNumber": "",
        "surname": "",
        "playerID": this.$uuid.v1(),
        "playerEditable": true,
        "goals": [],
        "bookings": []
      })

      this.$emit("updateLineup", this.teamPlayers)
    },

    removePlayer(index) {
      if (confirm("Are you sure you want to delete this player?")) {
        this.teamPlayers.splice(index, 1);

        this.$emit("updateLineup", this.teamPlayers)
      }
    },

    savePlayer(player, index) {
      this.teamPlayers[index].playerEditable = false

      this.$emit("updateLineup", this.teamPlayers)
    },

    addEvent(type, player, mainIndex) {
      console.log("Add event:", player);
      const combined = false 

      if (type === "Goal") {
        this.teamPlayers[mainIndex].goals.push({
          "type": type,
          "time": this.minute
        })
      } else if (type === "Yellow" || type === "Red") {
        this.teamPlayers[mainIndex].bookings.push({
          "card": type,
          "time": this.minute
        })
      }

      this.$emit("updateLineup", this.teamPlayers)
    },
  }
}
</script>

<style lang="scss" scoped>
  .add-player {
    margin: 0;
  }

  .lineup {
    margin: 20px 0 0;
    font-weight: 500;
  }

  .player {
    position: relative;
    padding: 12px;
    margin: 0 0 20px;
    background-color: #f8f8f8;
    border: 1px solid #DCD4DE;
    border-radius: 4px;

    &__remove-button {
      position: absolute;
      top: 40px;
      right: -30px;
      z-index: 2;
    }

    &__number {
      margin: 0 10px 0 0;
      max-width: 60px;
    }

    &__name {
      margin: 0;
    }

    &__detail {
      display: flex;
      flex-direction: row;
    }

    &__events {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 0 10px;
    }

    &__event {
      margin: 0 8px 0 0;
      padding: 2px 4px;
      color: #fff;
      font-size: 12px;
      background-color: #67247C;
      // border: 1px solid #ccc;
      border-radius: 4px;
    }

    &__event-add {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &__minute  {
      margin: 0 10px 0 0;
      max-width: 60px;
      width: 100%;
    }

    &__actions {
      margin: 0;
    }

    &__action {
      margin: 0 8px 8px 0;
      padding: 3px 6px;
      background-color: #DFE3E9;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
</style>
