<template>
  <section class="min-by-min">
    
    <template v-if="betaFeatures.default.CustomCommentary">
      <div class="min-by-min__add">

        <p style="display:none">{{ customPost }}</p>

        <p>Kick off: {{ matchUTC | moment('HH:mm:ss') }}</p>

        <v-layout>
          <div class="flex sm12 md2 mr-1">
            <label class="label" for="hour">Hour</label>
            <v-select
              id="hour"
              :items="hours"
              v-model="time.hour"
              solo
              flat>
            </v-select>
          </div>

          <div class="flex sm12 md2 mr-1">
            <label class="label" for="minute">Min</label>
            <v-select
              id="minute"
              :items="minutes"
              v-model="time.minute"
              solo
              flat>
            </v-select>
          </div>

          <div class="flex sm12 md2 mr-1">
            <label class="label" for="seconds">Secs</label>
            <v-select
              id="seconds"
              :items="seconds"
              v-model="time.second"
              solo
              flat>
            </v-select>
          </div>
        </v-layout>

        <v-layout>
          <div class="flex sm12 md4 mr-2">
            <label class="label" for="custom-time">Display Time</label>
            <v-text-field
              id="custom-time"
              placeholder="45'+2'"
              v-model="customPost.time"
              solo
              flat />
          </div>

          <div class="flex sm12 md4">
            <label class="label" for="displayType">Display Type</label>
            <v-select
              id="displayType"
              :items="displayTypes"
              item-text="name"
              item-value="value"
              v-model="customPost.displayType"
              @change="updateType($event)"
              solo
              flat>
            </v-select>
          </div>
        </v-layout>

        <template v-if="customPost.displayType === 'text'">
          <p v-if="existingCommentaryID">Editing: {{ existingCommentaryID }}</p>
          <v-layout>
            <div class="flex sm12 md4">
              <label class="label" for="eventType">Event Type</label>
              <v-select
                id="eventType"
                :items="eventTypes"
                item-text="name"
                item-value="value"
                v-model="customPost.content.eventType"
                solo
                flat>
              </v-select>
            </div>

          </v-layout>

          <v-layout>

            <div class="flex sm12 md12 mr-3">

              <label class="label">Image</label>
                        
              <ImageUploader title="Promo Image" :widthOptionEnabled="false" :mediaLibraryID="customPost.content.image.mediaLibraryID" @update="updateImage($event)"/>

              <label class="label" for="content">Comment</label>

              <tinymce-editor v-model="customPost.content.comment"
                api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
                :init="{
                height: '500px',
                menubar: false,
                plugins: 'table link paste wordcount hr lists textpattern',
                toolbar:
                  'undo redo | styleselect | bold italic | \
                  link blockquote table | \
                  hr | \
                  bullist numlist | removeformat',
                resize: false,
                textpattern_patterns: [
                  {start: '//button', replacement: '<a class=\'button \'>Button here</a>'}
                ],
                link_class_list: [
                  {title: 'None', value: ''},
                  {title: 'Primary on Dark', value: 'button button--primary-dark'},
                  {title: 'Primary on Light', value: 'button button--primary-light'},
                  {title: 'Ghost on Dark', value: 'button button--ghost-dark'},
                  {title: 'Ghost on Light', value: 'button button--ghost-light'},
                  {title: 'Label on Dark', value: 'button button--label-dark'},
                  {title: 'Label on Light', value: 'button button--label-light'},
                ],
                style_formats: [
                  {title: 'Headers', items: [
                    //{title: 'Header 1', format: 'h1'},
                    {title: 'Header 2', format: 'h2'},
                    {title: 'Header 3', format: 'h3'},
                    {title: 'Header 4', format: 'h4'},
                    {title: 'Header 5', format: 'h5'},
                    {title: 'Header 6', format: 'h6'}
                  ]},
                  {title: 'Inline', items: [
                    {title: 'Bold', icon: 'bold', format: 'bold'},
                    {title: 'Italic', icon: 'italic', format: 'italic'},
                    {title: 'Underline', icon: 'underline', format: 'underline'},
                    //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                    //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                    //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                    //{title: 'Code', icon: 'code', format: 'code'}
                  ]},
                  {title: 'Blocks', items: [
                    {title: 'Paragraph', format: 'p'},
                    {title: 'Blockquote', format: 'blockquote'},
                    //{title: 'Div', format: 'div'},
                    //{title: 'Pre', format: 'pre'}
                  ]},
                  {title: 'Alignment', items: [
                    {title: 'Left', icon: 'alignleft', format: 'alignleft'},
                    {title: 'Center', icon: 'aligncenter', format: 'aligncenter'},
                    {title: 'Right', icon: 'alignright', format: 'alignright'},
                    //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
                  ]},
                  {title: 'Small', selector: 'p', classes: 'small'}
                ],
                style_formats_merge: false,
                block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote'
              }"></tinymce-editor>
            </div>
          </v-layout>
        </template>

        <v-layout v-if="customPost.displayType === 'embed'">
          <div class="flex sm12 md12 mr-3">
            <label class="label" for="embed">Embed code</label>
            <v-textarea
              id="embed"
              v-model="customPost.content.embed"
              rows="16"
              solo
              flat
            ></v-textarea>
          </div>
        </v-layout>

        <div>
          <v-btn
            v-if="!existingCommentaryID"
            class="post-button"
            color="accent"
            type="button"
            depressed
            @click="addPost()"
          >
            Add Post
          </v-btn>

          <template v-else>
            <v-btn
              class="post-button mr-3"
              color="accent"
              type="button"
              depressed
              @click="updatePost()"
            >
              Update Post
            </v-btn>

            <v-btn
              class="post-button"
              color="grey"
              type="button"
              depressed
              @click="cancelUpdate()"
            >
              Cancel update
            </v-btn>
          </template>
        </div>
      </div>
    </template>

    <ol class="min-by-min__feed">
      <p>Feed refreshes every 30 seconds <span v-if="refreshing">[Updating]</span> <button type="button" class="min-by-min__force" @click="getFeed()">Force refresh</button></p>
      <li class="min-by-min__feed-item" v-for="item in commentary" :key="item.id">
        <div class="min-by-min__meta">
          <time class="min-by-min__time" @click="copyTime(item.timestamp, item.time)">
            <span>{{ item.timestamp | moment('HH:mm:ss') }}</span>
            <span v-if="item.minute != 0 && item.second != 0 && item.type"> - {{ item.minute }} mins {{ item.second }} secs</span>
          </time>

          <img v-if="item.type" src="@/assets/partner-assets/opta.png" height="10" alt="Opta" />

          <div v-else>
            <v-img
              :src="require('../../assets/gamechanger-bolt--purple.svg')"
              class="min-by-min__gamechanger-bolt"
              contain
              height="15"
              width="15"
            ></v-img>
          </div>
        </div>

        <div class="min-by-min__content" v-if="item.type">
          <p class="min-by-min__opta-type">{{ item.type }}</p>
          <p class="min-by-min__opta-comment">{{ item.comment }}</p>
        </div>

        <div class="min-by-min__content" v-else-if="item.displayType === 'text'">
          <p v-if="item.content.eventType" class="min-by-min__custom-type">Event type: {{ item.content.eventType }}</p>
          <div v-if="item.content.comment" v-html="item.content.comment"></div>
          <template v-if="item.content.image">
          <img v-if="item.content.image.imageKey" class="min-by-min__image" :src="`${imageDomain}/${item.content.image.imageKey}`" />
          </template>
        </div>

        <div class="min-by-min__content" v-else-if="item.displayType === 'embed'" v-html="item.content.embed">
        </div>

        <div class="min-by-min__content" v-else-if="item.displayType === 'image'">
          <img class="min-by-min__image" :src="`${imageDomain}/${item.content.image.imageKey}`" />
        </div>

        <div class="min-by-min__actions" v-if="!item.type">
          <button type="button" class="min-by-min__action-button" @click="loadExistingPost(item)">Edit</button>

          <button type="button" class="min-by-min__action-button" @click="deletePost(item.commentaryID)">Delete</button>
        </div>
      </li>
    </ol>
  </section>
</template>

<script>
import Vue from "vue"
import axios from "axios"
import moment from "moment"
import Editor from "@tinymce/tinymce-vue";
import ImageUploader from "@/components/Library/ImageUploader"

export default {
  name: "MinByMin",

  components: {
    "tinymce-editor": Editor,
    ImageUploader
  },

  props: {
    seasonID: {
      type: Number
    },
    teamID: {
      type: String
    },
    matchID: {
      type: String
    },
    matchUTC: {
      type: String
    }
  },

  data:() => ({
    imageDomain: process.env.VUE_APP_IMAGEURL,
    refreshing: true,
    existingCommentaryID: null,
    commentary: [],
    time: {
      hour: "00",
      minute: "00",
      second: "00"
    },
    customPost: {
      matchID: null,
      timestamp: null,
      time: null,
      displayType: "text",
      content: {
        comment: "",
        image: {},
        eventType: "",
        team: ""
      }
    },
    hours: [],
    minutes: [],
    seconds: [],
    displayTypes: [
      { name: "Content", value: "text" },
      { name: "Embed", value: "embed" }
    ],
    eventTypes: [
      { name: "Blank", value: "" },
      { name: "Substitute", value: "Substitute" },
      { name: "Goal", value: "Goal" },
      { name: "Shot", value: "Shot" },
      { name: "Shot blocked", value: "Shot blocked" },
      { name: "Shot saved", value: "Saved" },
      { name: "Shot missed", value: "Missed" },
      { name: "Hits the post", value: "Hits the post" },
      { name: "Hits the crossbar", value: "Hits the crossbar" },
      { name: "Own goal", value: "Own goal" },
      { name: "Free kick won", value: "Free kick" },
      { name: "Corner", value: "Corner" },
      { name: "Offside", value: "Offside" },
      { name: "Penalty won", value: "Penalty" },
      { name: "Foul", value: "Foul" },
      { name: "Yellow card", value: "Yellow card" },
      { name: "Second yellow", value: "Second yellow" },
      { name: "Red card", value: "Red card" },
      { name: "Lineup", value: "Lineup" },
      { name: "Kickoff", value: "Kickoff" },
      { name: "Half time", value: "Half time" },
      { name: "Full time", value: "Full time" },
      { name: "Var check", value: "VAR check" },
      { name: "Var decision", value: "VAR decision" },
      { name: "Var cancelled goal", value: "VAR cancelled goal" },
      { name: "Player retired", value: "Player retired" }
    ],
    betaFeatures: null,
  }),

  created() {
    this.betaFeatures = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
  },

  mounted() {
    this.customPost.matchID = this.matchID
    this.getFeed()
    this.createHoursArray()
    this.createMinutesArray()
    this.createSecondsArray()
    // this.updateMinutesArray()

    this.time.hour = moment(this.matchUTC).format("HH")
    this.time.minute = moment(moment(this.matchUTC).subtract(5, "minutes")).format("mm")
    this.time.second = moment(this.matchUTC).format("ss")

    this.autoRefresh()
  },

  updated() {
    // this.time.hour = moment(this.matchUTC).format('HH')
    // this.time.minute = moment(moment(this.matchUTC).subtract(5, 'minutes')).format('mm')
    // this.time.second = moment(this.matchUTC).format('ss')
  },

  methods: {
    autoRefresh() {
      setInterval(() => {
        console.log("Auto Commentary update")
        this.getFeed()
      }, 30000);
    },

    createSecondsArray() {
      for (let i = 0; i <= 59; i++) {
        const string = i.toString()
        this.seconds.push(string.padStart(2, "0"));
      }
    },

    createMinutesArray() {
      for (let i = 0; i <= 59; i++) {
        const string = i.toString()
        this.minutes.push(string.padStart(2, "0"));
      }
    },

    createHoursArray() {
      for (let i = 0; i <= 23; i++) {
        const string = i.toString()
        this.hours.push(string.padStart(2, "0"));
      }
    },

    getFeed() {
      console.log(`Feed: ${process.env.VUE_APP_WEBAPI}/fixtures/opta/getsinglecommentary?matchID=${this.matchID}&seasonID=${this.seasonID}&teamID=${this.teamID}&showCustom=true`)

      this.refreshing = true
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_WEBAPI}/fixtures/opta/getsinglecommentary?matchID=${this.matchID}&seasonID=${this.seasonID}&teamID=${this.teamID}&showCustom=true`
      })
      .then(response => {
        console.log("Commentary", response)
        this.refreshing = false
        if (response.data.body.commentary) {
          this.commentary = response.data.body.commentary
        }
      })
      .catch(e => {
        console.error("Problem commentary", e);
      });
    },

    createUTC() {
      return new Promise((resolve, reject) => {
        const matchDate = moment(this.matchUTC).format("YYYY-MM-DD")
        const commentTime = moment(`${matchDate}T${this.time.hour}:${this.time.minute}:${this.time.second}`).format("HH:mm:ss.SSSZ")

        console.log("matchDate", matchDate)
        console.log("commentTime", commentTime)

        this.customPost.timestamp = moment(matchDate + " " + commentTime).utc().format()

        resolve("Success")

        console.log("timestamp", this.customPost.timestamp)
      })
    },

    addPost() {
      console.log("addPost", this.customPost)

      if (this.customPost.time === "") {
        this.customPost.time = null
      }

      this.createUTC().then(() => {
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${process.env.VUE_APP_BASEURL}/fixtures/customcommentary/add?seasonID=${this.seasonID}&teamID=${this.teamID}`,
            headers: { "Authorization": jwt },
            data: this.customPost
          })
          .then(response => {
            console.log("Commentary Added", response)
            this.customPost.content = {}
            this.customPost.displayType = null
            this.existingCommentaryID = null

            this.getFeed()
          })
          .catch(e => {
            console.error("Problem commentary", e);
          });
        });
      })
    },

    copyTime(timestamp, time) {
      this.time.hour = moment(timestamp).format("HH")
      this.time.minute = moment(timestamp).format("mm")
      this.time.second = moment(timestamp).format("ss")

      this.customPost.time = time
    },

    loadExistingPost(item) {
      this.existingCommentaryID = item.commentaryID

      this.customPost.matchID = JSON.parse(JSON.stringify(item.matchID));
      this.customPost.timestamp = JSON.parse(JSON.stringify(item.timestamp));
      this.customPost.time = JSON.parse(JSON.stringify(item.time));
      this.customPost.displayType = JSON.parse(JSON.stringify(item.displayType));
      this.customPost.content = JSON.parse(JSON.stringify(item.content));

      this.time.hour = moment(item.timestamp).format("HH")
      this.time.minute = moment(item.timestamp).format("mm")
      this.time.second = moment(item.timestamp).format("ss")
    },

    cancelUpdate() {
      this.customPost.content = {}
      this.customPost.displayType = null
      this.customPost.time = null
      this.existingCommentaryID = null
    },

    updateType($event) {
      if ($event === "text" && !this.customPost.content.text) {
        this.customPost.content.image = {}
      }
    },

    updatePost() {
      if (this.customPost.time === "") {
        this.customPost.time = null
      }

      this.createUTC().then(() => {
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${process.env.VUE_APP_BASEURL}/fixtures/customcommentary/update?seasonID=${this.seasonID}&teamID=${this.teamID}`,
            headers: { "Authorization": jwt },
            data: {
              commentaryID: this.existingCommentaryID,
              matchID: this.customPost.matchID,
              timestamp: this.customPost.timestamp,
              time: this.customPost.time,
              displayType: this.customPost.displayType,
              content: this.customPost.content
            }
          })
          .then(response => {
            console.log("Commentary Updated", response)
            this.customPost.content = {}
            this.customPost.displayType = null
            this.customPost.time = null
            this.existingCommentaryID = null

            this.getFeed()
          })
          .catch(e => {
            console.error("Problem commentary", e);
          });
        });
      });
    },

    deletePost(commentaryID) {
      if (confirm("Are you sure you want to delete this post?")) {
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${process.env.VUE_APP_BASEURL}/fixtures/customcommentary/delete?seasonID=${this.seasonID}&teamID=${this.teamID}&commentaryID=${commentaryID}`,
            headers: { "Authorization": jwt }
          })
          .then(response => {
            console.log("Commentary Deleted", response)
            this.customPost.content = {}
            this.customPost.displayType = null
            this.customPost.time = null
            this.existingCommentaryID = null

            this.getFeed()
          })
          .catch(e => {
            console.error("Problem commentary", e);
          });
        });
      }
    },

    updateImage(payload) {
      console.log("Update image:", payload)
      this.customPost.content.image = payload
    }
  }
}
</script>

<style scoped lang="scss">
  .min-by-min {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    &__feed {    
      display: block;
      height: 600px;
      width: 50%;
      overflow-y: scroll;
      padding: 16px;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      background-color: #fff;
    }

    &__feed-item {
      display: block;
      padding: 0;
      margin: 0 0 16px;
      border: 1px solid #000;
    }

    &__meta {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 4px 8px;
      font-size: 12px;
      background-color: #E6E8F1;
    }

    &__content {
      display: block;
      padding: 8px;
      font-size: 12px;
    }

    &__image {
      width: 100%;
    }

    &__custom-type {
      font-weight: 500;
    }

    &__opta-type {
      margin: 0 0 6px;
      padding: 0;
      font-weight: 500;
    }

    &__opta-comment {
      margin: 0;
      padding: 0;
    }

    &__actions {
      padding: 4px 8px;
      color: #212121;
      font-size: 12px;
      background-color: #d1c1d6;
    }

    &__action-button {
      margin-right: 16px;
    }

    &__time {
      cursor: pointer;
    }

    &__add {
      width: 50%;
    }

    &__force {
      float: right;
      font-size: 12px;
      outline: none;
    }
  }

  .post-button {
    margin: 16px 0 0;
  }
</style>

<style>

</style>
